// Copyright © 2022 Move Closer

import 'reflect-metadata'
import 'wicg-inert'
// @ts-expect-error - For the unknown reasons the `.d.ts` file won't work.
import NoSSR from 'vue-no-ssr'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueMeta from 'vue-meta'
import { BootstrapDSL } from '@movecloser/ui-core'
import { WindowService } from '@movecloser/front-core'

import Configuration from '@plugin/configuration'
import Inversify from '@plugin/inversify'
import { BootedApp, createApp } from '@/bootstrap/app'
import { bootstrapDSLConfiguration } from '@config/dsl'
import { Context } from '@contract/context'
import { initSentry } from '@support/sentry'

import './scss/index.scss'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(BootstrapDSL, bootstrapDSLConfiguration)
Vue.use(Configuration)
Vue.use(Inversify)
Vue.use(VueI18n)
Vue.use(VueMeta)

Vue.component('no-ssr', NoSSR)

export default (context: Context): Promise<BootedApp> => {
  return createApp(context).then(bootedApp => {
    const { configuration, container, i18n, router, store } = bootedApp

    if (WindowService.isDefined && process.env.VUE_APP_ENV !== 'local') {
      initSentry()
    }

    return new Vue({
      configuration,
      container,
      i18n,
      render: h => h(App),
      router,
      store
    })
  })
}
