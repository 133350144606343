// Copyright © 2022 Move Closer

import VueI18n from 'vue-i18n'
import VueRouter, { RouteConfig } from 'vue-router'
import { Bootstrapper, Container, IConfiguration, RoutesStack, StoreStack } from '@movecloser/front-core'
import { ModuleTree } from 'vuex'

import { config } from '@config/index'
import { Context } from '@contract/context'
import { getEntryPointFromContext, preventRedirectionLoop } from '@support/entry-point'
import { ISetupService, SetupServiceType } from '@service/setup'
import { State, Store } from '@contract/store'

import { createI18n, FALLBACK_LOCALE } from './i18n'
import { createRouter } from './router'
import { createStore } from './store'

export interface BootedApp {
  configuration: IConfiguration
  container: Container
  i18n: VueI18n
  router: VueRouter
  store: Store
}

// noinspection JSUnusedLocalSymbols
/**
 * Creates the application instance.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (edited)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function createApp (context: Context): Promise<BootedApp> {
  const bootstrapper = new Bootstrapper(config)
  await bootstrapper.boot()

  const configuration: IConfiguration = bootstrapper.getConfiguration()
  const container: Container = bootstrapper.getContainer()
  const routesStack: RoutesStack = bootstrapper.getRoutesStack()
  const storeStack: StoreStack = bootstrapper.getStoreStack()

  const store = createStore(storeStack as ModuleTree<State>)
  let locale = FALLBACK_LOCALE

  const entryPoint = getEntryPointFromContext(context)
  if (entryPoint) {
    const setupService: ISetupService = container.get(SetupServiceType)

    try {
      await setupService.initApp(entryPoint, context.isClient)
      await store.dispatch('setMaintenanceMode', setupService.isMaintenanceMode)
      locale = setupService.currentLocale.toLowerCase()
    } catch (error) {
      if (error.status === 503) {
        await store.dispatch('setMaintenanceMode', setupService.isMaintenanceMode)
      } else {
        if (![400, 404].includes(error.status)) {
          context.error(error.message, error.status)
        }

        // For all environments different than 'local' there's no need to run the app
        // without SSR so we can printout an error easily. But if the fallback redirection
        // ended up with an error, we should avoid too many redirections loop.
        const redirectionTarget = process.env.VUE_APP_SETUP_REDIRECT ?? '/'
        const nonProductionEnvironments = configuration.byKey<string[]>('nonProductionEnvironments', false, [])
        if (
          nonProductionEnvironments && (
            !nonProductionEnvironments.includes(process.env.VUE_APP_ENV || 'local') ||
            preventRedirectionLoop(entryPoint, redirectionTarget)
          )
        ) {
          context.error(error.message, error.status)
        }

        context.redirect(redirectionTarget)
      }
    }
  }

  const router = createRouter(routesStack as RouteConfig[], container)
  const i18n = createI18n(locale)

  return { configuration, container, i18n, router, store }
}
