// Copyright © 2022 Move Closer

// This code is run on the server side where we can't use the `import` statement.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path')

export default {
  plugins: [
    [
      '@uvue/core/plugins/vuex',
      {
        onHttpRequest: true,
        fetch: true
      }
    ],
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'async-data.ts'),
    '@uvue/core/plugins/prefetch',
    '@uvue/core/plugins/middlewares',
    '@uvue/core/plugins/errorHandler',
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'error-clear.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'validate-setup.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'progress-bar.ts'),
    path.join(__dirname, 'src', 'shared', 'plugins', 'uvue', 'user-agent', 'user-agent.plugin.ts')
  ],
  css: {
    normal: 'extract',
    vue: 'extract'
  }
}
