// Copyright © 2021 Move Closer

import { AxiosDriver } from '@movecloser/front-core/lib/services/http/axios-driver'
import { IHttpConnectorConfig } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const http: IHttpConnectorConfig = {
  drivers: {
    bnp: () => {
      return new AxiosDriver({
        baseURL: process.env.VUE_APP_HTTP_API_BASEURL,
        headers: {
          Accept: 'application/json',
          'X-Client': process.env.VUE_APP_HTTP_API_CLIENT
        }
      }, process.env.VUE_APP_DEBUG_HTTP === 'true')
    }
  },
  default: 'bnp'
}
