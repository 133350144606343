// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const resources: ResourcesRegistry = {
  content: {
    connection: 'bnp',
    prefix: 'registry',
    methods: {
      get: {
        url: '{slug}',
        method: Methods.Get,
        params: ['slug']
      }
    }
  },
  jobOffers: {
    connection: 'bnp',
    prefix: '',
    methods: {
      search: {
        url: 'search/offers',
        method: Methods.Get
      }
    }
  },
  newsletter: {
    connection: 'bnp',
    prefix: 'newsletter',
    methods: {
      subscribe: {
        url: 'subscribe',
        method: Methods.Post
      }
    }
  },
  root: {
    connection: 'bnp',
    prefix: '',
    methods: {
      search: {
        url: 'search',
        method: Methods.Get
      }
    }
  },
  setup: {
    connection: 'bnp',
    prefix: '',
    methods: {
      init: {
        url: 'init',
        method: Methods.Get,
        meta: { omitSite: true }
      }
    }
  }
}
