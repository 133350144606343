// Copyright © 2021 Move Closer

import { AppConfig } from '@movecloser/front-core'

import { AppModules as modules } from './modules'
import { http } from './http'
import { middlewares } from './middlewares'
import { modalsRegistry as modals } from './modal'
import { resources } from './resources'
import { ROUTER_DRIVER as router } from './router'
import { services } from './services'
import { STORE_DRIVER as store } from './store'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const config: AppConfig = {
  http,
  middlewares,
  modals,
  modules,
  nonProductionEnvironments: ['local'],
  resources,
  router,
  services,
  store,

  // Has to be defined, even if it's empty, or an error will be thrown.
  modalConfig: {}
}
