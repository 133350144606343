// Copyright © 2021 Move Closer

import {
  ApiConnectorFactory,
  ConnectorFactory,
  Inject,
  Injectable,
  IResponse,
  mapModel,
  QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { buildMetaTags } from '@support/meta'
import { ISiteService, SiteServiceType } from '@service/site'
import { RegistryResponse } from '@/shared/modules'
import { resolveFromStatus } from '@exception/connector-errors'

import { contentAdapterMap, ContentData, IContent } from '../../models'

import { IContentRepository } from './content.contracts'

/**
 * TODO: Description.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class ContentRepository extends Repository<ContentData, IContent> implements IContentRepository {
  /**
   * TODO: Description.
   */
  protected sites: ISiteService

  constructor (@Inject(SiteServiceType) sites: ISiteService, @Inject(ApiConnectorFactory) connectorFactory: ConnectorFactory) {
    super(connectorFactory)
    this.sites = sites
  }

  /**
   * TODO: Description.
   */
  public async load (slug: string, query: QueryParams = {}, translateCode: boolean = true): Promise<RegistryResponse> {
    const response: IResponse = await this.connector.call(
      'content', 'get', { slug }, { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        translateCode ? resolveFromStatus(response.status) : response.status,
        { ...response.errors?.errors, ...response.errors?.data, slug: slug }
      )
    }

    const related = response.data.meta.dicts || {}

    return {
      content: mapModel<ContentData>(response.data.data, contentAdapterMap),
      meta: response.data.data?.properties?.meta
        ? await buildMetaTags(response.data.data?.properties?.meta, related)
        : [{ name: 'robots', content: 'index, follow' }],
      related
    }
  }
}
