// Copyright © 2021 Move Closer

import Vue from 'vue'
import Vuex, { ModuleTree } from 'vuex'
import { Route } from 'vue-router'

import { State, Store } from '@contract/store'

Vue.use(Vuex)

/**
 * Creates the Vuex instance with all add-ons.
 *
 * @param modules - Store configuration from the `@AppModule`s.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const createStore = (modules: ModuleTree<State>): Store => {
  return new Vuex.Store<State>({
    modules,

    state: () => ({
      device: {
        isDesktop: false,
        isPhone: true,
        isTablet: false,
        userAgent: null
      },
      isMaintenanceMode: false,
      routesHistory: []
    }),

    actions: {
      /**
       * Pushes the passed-in `Route` to the route's history.
       *
       * @param commit
       * @param state
       * @param route - The `Route` object to add to the history.
       */
      pushRouteToHistory ({ commit, state }, route: Route): void {
        commit('SET_ROUTES_HISTORY', [...state.routesHistory, route])
      },

      /**
       * Sets the `device` state property to the passed-in value.
       *
       * @param commit
       * @param device - Desired state of the `device` state property.
       */
      setDevice ({ commit }, device: State['device']): void {
        commit('SET_DEVICE', device)
      },

      /**
       * Sets the `isMaintenanceMode` state property to the passed-in value.
       *
       * @param commit
       * @param isMaintenanceMode - Desired state of the `isMaintenanceMode` state property.
       */
      setMaintenanceMode ({ commit }, isMaintenanceMode: State['isMaintenanceMode']): void {
        commit('SET_MAINTENANCE_MODE', isMaintenanceMode)
      }
    },

    mutations: {
      SET_DEVICE (state, device: State['device']): void {
        state.device = device
      },

      SET_MAINTENANCE_MODE (state, isMaintenanceMode: State['isMaintenanceMode']): void {
        state.isMaintenanceMode = isMaintenanceMode
      },

      SET_ROUTES_HISTORY (state, routesHistory: State['routesHistory']): void {
        state.routesHistory = routesHistory
      }
    },

    getters: {
      /**
       * Determines whether the app is running on a desktop computer.
       */
      isDesktop (state): boolean {
        return state.device.isDesktop
      },

      /**
       * Determines whether the app is in the maintenance mode.
       */
      isMaintenanceMode (state): boolean {
        return state.isMaintenanceMode
      },

      /**
       * Determines whether the app is running on a mobile phone OR a tablet.
       */
      isMobile (state): boolean {
        return state.device.isPhone || state.device.isTablet
      },

      /**
       * Determines whether the app is running on a mobile phone.
       */
      isPhone (state): boolean {
        return state.device.isPhone
      },

      /**
       * Determines whether the app is running on a tablet.
       */
      isTablet (state): boolean {
        return state.device.isTablet
      },

      /**
       * Array of the `Route` objects which represents the actual browsing history.
       */
      routesHistory (state): Route[] {
        return state.routesHistory
      }
    }
  })
}
