// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Lukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const siteAdapterMap: MappingConfig = {
  address: 'address',
  domain: 'domain',
  favicon: 'favicon',
  id: 'id',
  locale: 'locale',
  logo: 'logo',
  name: 'name',
  properties: {
    type: MappingTypes.Function,
    value: item => {
      if (item.domain === 'premium.defence24.pl') {
        // FIXME: NOTE! This is mocking.
        return { ...item.properties, noAccessAction: 'premium' }
      }
      return item.properties
    }
  },
  postfix: 'postfix'
}
