// Copyright © 2021 Move Closer

import { MetaPropertyName, MetaPropertyProperty } from 'vue-meta'

import { ContentMeta, RelatedRecord } from '@/shared/modules'
import { RelatedService, relatedTypeDriverRegistryFactory } from '@service/related'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export async function buildMetaTags (contentMeta: ContentMeta, related: RelatedRecord): Promise<(MetaPropertyName | MetaPropertyProperty)[]> {
  const meta: (MetaPropertyName | MetaPropertyProperty)[] = []
  const metaTags: { [key: string]: { name: string; key?: 'name' | 'property' } } = {
    ogDescription: {
      name: 'og:description',
      key: 'property'
    },
    ogImage: {
      name: 'og:image',
      key: 'property'
    },
    ogTitle: {
      name: 'og:title',
      key: 'property'
    },
    twitterDescription: { name: 'twitter:description' },
    twitterImage: { name: 'twitter:image' },
    twitterTitle: { name: 'twitter:title' },
    robots: { name: 'robots' }
  }

  const relatedService = new RelatedService(relatedTypeDriverRegistryFactory())
  relatedService.storeRelated(related)

  if (typeof contentMeta.robots !== 'string') {
    contentMeta.robots = 'index, follow'
  }

  for (let [p, value] of Object.entries(contentMeta)) {
    const metaTag = metaTags[p as keyof typeof metaTags]
    const name = metaTag ? metaTag.name ?? p : p
    const key: 'name' | 'property' = metaTag ? metaTag.key ?? 'name' : 'name'

    try {
      if (value && typeof value !== 'string' && 'type' in value) {
        const selectedRatios = []
        const resolved = await relatedService.resolve(value)

        for (let i = 0; i < resolved.srcset.length; i++) {
          if (resolved.srcset[i].ratio === 'original') {
            selectedRatios.push(resolved.srcset[i])
          }
        }

        selectedRatios.sort((a, b) => {
          return b.width - a.width
        })

        value = selectedRatios[0].url
      }

      if (value && typeof value !== 'string' && 'url' in value) {
        value = value.url as string
      }

      const metaItem = {
        [key]: name,
        content: value
      }

      if (key === 'name') {
        meta.push(metaItem as MetaPropertyName)
      } else {
        meta.push(metaItem as MetaPropertyProperty)
      }
    } catch (e) {
      console.info(e)
    }
  }

  return meta
}
