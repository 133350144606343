// Copyright © 2021 Move Closer

import { InternalServerErrorMiddlewareType, ValidationMiddlewareType } from '@movecloser/front-core'

import { IdentityMiddlewareType } from '@service/identity'
import { SiteMiddlewareType } from '@service/site'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const middlewares: symbol[] = [
  IdentityMiddlewareType,
  InternalServerErrorMiddlewareType,
  SiteMiddlewareType,
  ValidationMiddlewareType
]
