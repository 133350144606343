// Copyright © 2022 Move Closer

import 'reflect-metadata'
import { ComponentOptions, VueConstructor } from 'vue'
import { createDecorator } from 'vue-class-component'
import { interfaces } from 'inversify'

import { log } from '@/shared/modules'

let Vue: VueConstructor

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Inject = (identifier?: interfaces.ServiceIdentifier<unknown>) =>
  (proto: Vue, key: string): void => {
    let Type: unknown

    if (typeof Reflect !== 'undefined' && typeof Reflect.getMetadata === 'function') {
      Type = Reflect.getMetadata('design:type', proto, key)
    }

    return createDecorator((options: ComponentOptions<Vue>, key) => {
      options.computed = options.computed || {}

      options.computed[key] = function (this: Vue) {
        if (typeof this.$container === 'undefined') {
          log(`FATAL ERROR! [this.$container] is undefined! Failed to inject the [${identifier || Type}]!`, 'error')
          return
        }

        return this.$container.get(identifier || Type)
      }
    })(proto, key)
  }

function initInversify (this: Vue): void {
  if (this.$options.container) {
    this.$container = this.$options.container
  } else if (this.$options.parent && this.$options.parent.$container) {
    this.$container = this.$options.parent.$container
  }
}

function install (_Vue: VueConstructor): void {
  if (Vue && _Vue === Vue) {
    if (process.env.VUE_APP_ENV !== 'production') {
      throw new Error('[inversify] already installed.')
    }
  } else {
    _Vue.mixin({ beforeCreate: initInversify })
  }
}

export default { install }
