



























































































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import {
  AbstractSelectControlOption,
  BootstrapButton,
  BootstrapIcon,
  BootstrapSize,
  BootstrapTheme
} from '@movecloser/ui-core'

import { Inject, Responsive } from '../../../extensions'

import { SearchTileDefinition } from '../../modules/SearchResults'

import OptionsList from '../OptionsList/OptionsList.vue'
import { OptionsListColumnsDefinition, OptionsListItem } from '../OptionsList'
import { SearchOptionType } from './Search.config'

/**
 * @emits submit (formData: Search.formData) - When the User presses the "search" button.
 * @see Search.formData
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (edited)
 */
@Component<Search>({
  name: 'Search',
  components: { BootstrapButton, BootstrapIcon, OptionsList },
  created (): void {
    this.getFormDataFromQuery()
  },
  mounted (): void {
    this.eventBus.handle('app:remove-search-tile', (event: EventPayload<SearchTileDefinition | null>) => {
      if (event.payload && event.payload.type === SearchOptionType.TextQuery) {
        this.formData.text = null
      }
    })

    if (!this.isMobile) {
      this.addClickListener()
    }
  },
  beforeDestroy (): void {
    if (!this.isMobile) {
      this.removeClickListener()
    }
  }
})
export class Search extends Mixins<Responsive>(Responsive) {
  @Inject(EventbusType)
  private readonly eventBus!: IEventbus

  /**
   * Array of departments to be rendered as an `<option>` elements for the "department" `<select>` field.
   */
  @Prop({ type: Array, required: true })
  public readonly departments!: AbstractSelectControlOption[]

  /**
   * Determines whether the component should be put in the "loading" (waiting) state.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly loading!: boolean

  /**
   * Array of locations to be rendered as an `<option>` elements for the "location" `<select>` field.
   */
  @Prop({ type: Array, required: true })
  public readonly locations!: AbstractSelectControlOption[]

  /**
   * Array of jobs work models.
   */
  @Prop({ type: Array, required: true })
  public readonly jobsModels!: AbstractSelectControlOption[]

  @Prop({ type: String, required: true })
  public readonly openedOption!: string

  @Prop({ type: Array, required: true, default: () => [] })
  public readonly optionsList!: Array<OptionsListItem>

  @Prop({ type: Array, required: true, default: () => [] })
  public readonly selectedOptions!: Array<OptionsListItem>

  @Prop({ type: Object, required: true })
  public readonly columnsConfig!: OptionsListColumnsDefinition

  @Prop({ type: Boolean, required: false, default: false })
  public withGallery!: boolean

  public readonly BootstrapSize = BootstrapSize
  public readonly BootstrapTheme = BootstrapTheme
  public readonly optionType = SearchOptionType

  @Ref('search')
  public readonly searchRef!: HTMLElement

  @Ref('departmentsTrigger')
  public readonly departmentsTriggerRef!: HTMLElement

  @Ref('locationsTrigger')
  public readonly locationsTriggerRef!: HTMLElement

  @Ref('jobsModelsTrigger')
  public readonly jobsModelsTriggerRef!: HTMLElement

  /**
   * Data entered by the User.
   */
  public formData: {
    text: string | null
  } = {
    text: null
  }

  public getActiveLabel (type: string, defaultLabel: string = ''): string {
    const SELECTED_OPTION_LENGTH: number = this.isMobile ? 30 : 18

    if (this.selectedOptions.length === 0) {
      return defaultLabel
    }

    const options = this.selectedOptions
      .filter((option) => option.type === type)

    if (options.length === 0) {
      return defaultLabel
    }

    const fullLabel = options.length === 1
      ? options[0].label
      : options
        .map((option) => option.label)
        .join(', ')

    return fullLabel.length > (SELECTED_OPTION_LENGTH - 3)
      ? fullLabel.slice(0, SELECTED_OPTION_LENGTH - 3) + '...'
      : fullLabel
  }

  public getActiveClass (type: string): string {
    return this.openedOption === type ? '--active' : ''
  }

  public isLabelNotEmpty (type: string): boolean {
    return this.selectedOptions.some((option) => option.type === type)
  }

  public handleOptionOpen (type: string): void {
    this.setOptionsContainerOffsetLeft(type)
    this.$emit('openOptions', type)
  }

  /**
   * Sets container transform (from left) based on currently selected trigger
   * @param type
   */
  public setOptionsContainerOffsetLeft (type: string): void {
    let offset = 0
    const wrapperLeft = this.searchRef.getBoundingClientRect().left

    const departmentsTriggerWidth = this.departmentsTriggerRef.getBoundingClientRect().width
    const departmentsTriggerLeft = this.departmentsTriggerRef.getBoundingClientRect().left

    const locationsTriggerWidth = this.locationsTriggerRef.getBoundingClientRect().width
    const locationsTriggerLeft = this.locationsTriggerRef.getBoundingClientRect().left

    const jobsModelsTriggerWidth = this.jobsModelsTriggerRef.getBoundingClientRect().width
    const jobsModelsTriggerLeft = this.jobsModelsTriggerRef.getBoundingClientRect().left

    if (type === SearchOptionType.Departments) {
      offset = departmentsTriggerLeft - wrapperLeft + (departmentsTriggerWidth / 2)
    } else if (type === SearchOptionType.Locations) {
      offset = locationsTriggerLeft - wrapperLeft + (locationsTriggerWidth / 2)
    } else if (type === SearchOptionType.JobModel) {
      offset = jobsModelsTriggerLeft - wrapperLeft + (jobsModelsTriggerWidth / 2)
    }

    this.$emit('setOffset', offset)
  }

  /**
   * Handles the `@submit` event on the root `<form>` element.
   */
  public onSubmit (): void {
    this.$emit('submit', this.formData)
  }

  /**
   * Resolves the form data from the URL query.
   */
  private getFormDataFromQuery (): void {
    if (typeof this.$route.query.q === 'string') {
      this.formData.text = this.$route.query.q
    }
  }

  /**
   * Closes options on clicking outside (excludes triggers)
   */
  private closeOptionsOnClickOutside (e: Event): void {
    const excludedElements = [
      this.departmentsTriggerRef,
      this.locationsTriggerRef,
      this.jobsModelsTriggerRef
    ]
    const optionsListElement = document.getElementById('options-list')

    const isDescendant = function (parent: HTMLElement, child: HTMLElement) {
      let node = child.parentNode

      while (node) {
        if (node === parent) {
          return true
        }

        node = node.parentNode
      }

      return false
    }

    if (optionsListElement && isDescendant(optionsListElement, e.target as HTMLElement)) {
      return
    }

    if (!excludedElements.includes(e.target as HTMLElement)) {
      this.$emit('openOptions', '')
    }
  }

  private addClickListener (): void {
    if (typeof document !== 'undefined') {
      document.addEventListener('click', (e) => this.closeOptionsOnClickOutside(e))
    }
  }

  private removeClickListener (): void {
    if (typeof document !== 'undefined') {
      document.removeEventListener('click', (e) => this.closeOptionsOnClickOutside(e))
    }
  }
}

export default Search
