














import { Component, Vue } from 'vue-property-decorator'
import { BootstrapButton } from '@movecloser/ui-core'
import { IWindow, WindowType } from '@movecloser/front-core'
import { Inject } from '../../../extensions'

@Component<ScrollToTop>({
  name: 'ScrollToTop',
  components: { BootstrapButton },

  created (): void {
    this.setScrollListenerToWindow()
  },

  destroyed (): void {
    this.removeScrollListenerFromWindow()
  }
})
export class ScrollToTop extends Vue {
  @Inject(WindowType)
  protected readonly windowService!: IWindow

  /**
   * Determines the state of visibility of button.
   */
  private isVisibilitySet: boolean = false

  /**
   * Removes listener to scroll event of window.
   */
  private removeScrollListenerFromWindow (): void {
    if (typeof window === 'undefined') {
      return
    }

    this.windowService.removeEventListener('scroll', this.onScroll)
  }

  /**
   * Sets listener to scroll event of window.
   */
  private setScrollListenerToWindow (): void {
    if (typeof window === 'undefined') {
      return
    }

    this.windowService.addEventListener('scroll', this.onScroll)
  }

  /**
   * Listens to scroll of window.
   */
  private onScroll (): void {
    const scrollTop = this.windowService.native?.pageYOffset

    if (!scrollTop) {
      return
    }

    if (scrollTop > 1050) {
      if (this.isVisibilitySet) {
        return
      }

      this.isVisibilitySet = true
    } else {
      if (!this.isVisibilitySet) {
        return
      }

      this.isVisibilitySet = false
    }
  }

  /**
   * Handles scrolling to the top of the page.
   */
  public scrollToTop (): void {
    if (typeof window === 'undefined') {
      return
    }

    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
export default ScrollToTop
