// Copyright © 2021 Move Closer

import { UserComConfig } from '@/shared/modules'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const userComConfig: UserComConfig = {
  apiKey: process.env.VUE_APP_USER_COM_API_KEY ?? '',
  endpoint: process.env.VUE_APP_USER_COM_ENDPOINT ?? ''
}
