// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

/**
 * Registry of all available modals.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const modalsRegistry: ModalRegistry<VueConstructor| null> = {}
