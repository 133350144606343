// Copyright © 2021 Move Closer

import { IModuleConstructor } from '@movecloser/front-core'

import { ContentModule } from '@module/content'
import { RootModule } from '@module/root'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const AppModules: IModuleConstructor[] = [ContentModule, RootModule]
