// Copyright © 2021 Move Closer

import { ConnectorMiddleware, FoundResource, Headers, Injectable, Payload } from '@movecloser/front-core'

import { log } from '@/shared/modules'

import { ISiteService, ISiteModel } from './site.contracts'

/**
 * Middleware that decorates the API calls with the information about the currently-active domain.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class SiteMiddleware implements ConnectorMiddleware {
  private readonly sites: ISiteService

  constructor (siteService: ISiteService) {
    this.sites = siteService
  }

  public afterCall (): void {
    //
  }

  public beforeCall (resource: FoundResource, headers: Headers, body: Payload): { body: Payload; headers: Headers } {
    if (typeof resource.meta.omitSite === 'boolean' && resource.meta.omitSite) {
      return { body, headers }
    }

    log(['🏷 SiteMiddleware.beforeCall():', resource.url, this.domainHeader])

    return {
      body,
      headers: {
        ...headers,
        ...this.domainHeader
      }
    }
  }

  private get domainHeader (): Headers {
    const site: ISiteModel = this.sites.getActiveSite()
    const fallbackLocale = process.env.VUE_APP_HTTP_D24_API_LOCALE || 'en-GB'

    return { 'Accept-Language': site.locale || fallbackLocale, 'X-Site': `${site.id}` }
  }
}
