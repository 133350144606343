"use strict";
// Copyright © 2021 Move Closer
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Colorable = void 0;
const vue_property_decorator_1 = require("vue-property-decorator");
const contracts_1 = require("../../contracts");
const support_1 = require("../../support");
/**
 * Extendable component that's capable of receiving the `color` @Prop
 * and transforming it into the target CSS class using the specified registry.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let Colorable = class Colorable extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        /**
         * Registry that binds the `Color` with the applicable CSS class.
         */
        this.colorClassNameRegistry = {
            [contracts_1.Color.Black]: 'text-black',
            [contracts_1.Color.Body]: 'text-body',
            [contracts_1.Color.Primary]: 'text-primary',
            [contracts_1.Color.White]: 'text-white'
        };
    }
    /**
     * CSS class that handles the object's color.
     */
    get colorClassName() {
        if (typeof this.color !== 'string') {
            return '';
        }
        const className = this.colorClassNameRegistry[this.color];
        if (typeof className === 'undefined') {
            support_1.log(`Colorable.colorClassName(): There's no CSS class binding for the [${this.color}] color! Returning empty string!`, 'warn');
            return '';
        }
        return className;
    }
};
__decorate([
    vue_property_decorator_1.Prop({ type: String, required: false })
], Colorable.prototype, "color", void 0);
Colorable = __decorate([
    vue_property_decorator_1.Component({ name: 'Colorable' })
], Colorable);
exports.Colorable = Colorable;
