// Copyright © 2021 Move Closer

import { QueryParams } from '@movecloser/front-core'

import { RegistryResponse } from '@/shared/modules'

/**
 * TODO: Documentation (author).
 */
export const ContentRepositoryType = Symbol.for('IContentRepository')

/**
 * TODO: Documentation (author).
 */
export interface IContentRepository {
  /**
   * TODO: Documentation (description, params, returned value).
   */
  load (slug: string, query?: QueryParams, translateCode?: boolean): Promise<RegistryResponse>
}
