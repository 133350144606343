// Copyright © 2022 Move Closer

import { VueConstructor } from 'vue'

let Vue: VueConstructor

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl> (original)
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 */
function initConfiguration (this: Vue): void {
  if (this.$options.configuration) {
    this.$configuration = this.$options.configuration
  } else if (this.$options.parent && this.$options.parent.$configuration) {
    this.$configuration = this.$options.parent.$configuration
  }
}

function install (_Vue: VueConstructor): void {
  if (Vue && _Vue === Vue) {
    if (process.env.VUE_APP_ENV !== 'production') {
      throw new Error('[configuration] already installed.')
    }
  } else {
    _Vue.mixin({ beforeCreate: initConfiguration })
  }
}

export default { install }
