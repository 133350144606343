// Copyright © 2022 Move Closer

import { RoutesFactory } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const routesFactory: RoutesFactory = () => {
  return [
    {
      path: '*',
      name: 'display',
      component: () => import(
        /* webpackChunkName: "frame" */
        './views/ContentView/ContentView.vue'
      )
    }
  ]
}
