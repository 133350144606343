// Copyright © 2021 Move Closer

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Container, ContainersRegistry } from '@movecloser/page-builder'
import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * Determines whether the passed-in `Container` has correctly defined its `id` property.
 *
 * @param container - The `Container` to verify.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const hasId = (container: Container): boolean => (
  typeof container.id === 'number' || (
    typeof container.id === 'string' && container.id.length > 0
  )
)

/**
 * Translates array of containers to the `ContainersRegistry`.
 *
 * @param containers - Array of containers that is to be translated into the `ContainersRegistry`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
const containersArrayToRegistry = (containers: any[]): ContainersRegistry => {
  const registry: ContainersRegistry = {}

  for (let i = 0; i < containers.length; i++) {
    const containerId: string = `${i + 1}`

    registry[containerId] = JSON.parse(JSON.stringify(containers[i]))

    if (!hasId(registry[containerId])) {
      registry[containerId].id = containerId
    }
  }

  return registry
}

/**
 * Translates containers object to the `ContainersRegistry`.
 *
 * @param containers - Containers object that is to be translated into the `ContainersRegistry`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
const containersObjectToRegistry = (containers: any): ContainersRegistry => {
  const registry: ContainersRegistry = JSON.parse(JSON.stringify(containers))

  Object.entries(registry).forEach(([id, container]) => {
    if (!hasId(container)) {
      container.id = id
    }
  })

  return registry
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const contentAdapterMap: MappingConfig = {
  author: 'author',
  categoryUrl: 'categoryUrl',
  containers: {
    type: MappingTypes.Function,
    value: item => Array.isArray(item.containers)
      ? containersArrayToRegistry(item.containers)
      : containersObjectToRegistry(item.containers)
  },
  contentId: 'contentId',
  options: 'contentProperties',
  parent: 'parent',
  properties: 'properties',
  publicationDate: 'publicationDate',
  site: 'site',
  slots: 'slots',
  title: 'title',
  type: 'type',
  urlPath: 'urlPath'
}
