// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Addon, AddonData } from '@/shared/modules'

import { ContentData, IContent } from './content.contracts'

/**
 * TODO: Description.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class Content extends Model<ContentData> implements IContent {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'author', 'contentId', 'categoryUrl', 'options', 'parent', 'pdf', 'properties', 'publicationDate', 'site', 'slots', 'title', 'type', 'urlPath'
    ]
  }

  /**
   * Return configuration of Content.
   */
  public getOption (key: string, defaultValue: boolean = false): boolean {
    return this._data.options[key] ?? defaultValue
  }

  /**
   * Return Addon properties.
   */
  public getProperty (addon: Addon): AddonData {
    if (!this.hasProperty(addon)) {
      return {}
    }

    return this._data.properties[addon]
  }

  /**
   * Check if model has given Addon set.
   */
  public hasProperty (addon: Addon): boolean {
    return typeof this._data.properties[addon] !== 'undefined' && this._data.properties[addon] !== null
  }
}
