// Copyright © 2022 Move Closer

import { MetaInfo } from 'vue-meta'

import { App } from '@/App.vue'
import { getHtmlLangAttrValue } from '@support/i18n'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const getMetaInfo = (_this: App): MetaInfo => ({
  htmlAttrs: {
    lang: getHtmlLangAttrValue(_this.$i18n.locale)
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `${process.env.BASE_URL}icons/favicon-32x32.png`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `${process.env.BASE_URL}icons/favicon-16x16.png`
    },
    {
      rel: 'manifest',
      href: `${process.env.BASE_URL}site.webmanifest`
    },
    {
      rel: 'mask-icon',
      href: `${process.env.BASE_URL}icons/safari-pinned-tab.svg`,
      color: '#00965e'
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: `${process.env.BASE_URL}icons/apple-touch-icon.png`
    },
    {
      rel: 'preconnect',
      href: process.env.VUE_APP_HTTP_API_BASEURL
    },
    {
      rel: 'canonical',
      href: _this.$context.url
    }
  ],
  titleTemplate: `%s | ${_this.$t('_.bnp-careers')}`,
  meta: [
    ..._this.siteService.getActiveSiteMeta(),
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
    },
    {
      name: 'theme-color',
      content: '#00965e'
    },
    {
      name: 'msapplication-TileColor',
      content: '#00965e'
    },
    {
      name: 'msapplication-TileImage',
      content: `${process.env.BASE_URL}icons/mstile-144x144.png`
    },
    {
      name: 'apple-mobile-web-app-title',
      content: _this.$t('_.bnp-careers') as string
    },
    {
      name: 'application-name',
      content: _this.$t('_.bnp-careers') as string
    }
  ]
})
