// Copyright © 2021 Move Closer

import { IResponse } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum ConnectorErrors {
  Conflict = 'conflict',
  Forbidden = 'forbidden',
  NotFound = 'notfound',
  PaymentRequired = 'PaymentRequired',
  ServerError = 'ServerError',
  TemporaryUnavailable = 'TemporaryUnavailable',
  Validation = 'validation',
  Unauthenticated = 'unauthenticated',
  Unknown = 'unknown'
}

/**
 * TODO: Description.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const resolveFromStatus = (response: IResponse|number): ConnectorErrors => {
  switch (typeof response === 'number' ? response : response.status) {
    case 401:
      return ConnectorErrors.Unauthenticated
    case 402:
      return ConnectorErrors.PaymentRequired
    case 403:
      return ConnectorErrors.Forbidden
    case 404:
      return ConnectorErrors.NotFound
    case 409:
      return ConnectorErrors.Conflict
    case 422:
      return ConnectorErrors.Validation
    case 500:
      return ConnectorErrors.ServerError
    case 503:
      return ConnectorErrors.TemporaryUnavailable
    default:
      return ConnectorErrors.Unknown
  }
}
