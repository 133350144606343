


































import { DashmixCheck } from '@movecloser/ui-core'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { OptionsListItem, OptionsListProps } from './OptionsList.contracts'
import { optionsListColumnsDefaultConfig } from './OptionsList.config'

@Component<OptionsList>({
  name: 'OptionsList',
  components: {
    DashmixCheck
  }
})
export default class OptionsList extends Vue {
  @Inject({ from: 'isMobile', default: false })
  private readonly isMobile!: boolean

  @Prop({ type: Array, required: true, default: () => [] })
  public selected!: Array<OptionsListItem>

  @Prop({ type: Object, required: false, default: () => optionsListColumnsDefaultConfig })
  public readonly columns!: OptionsListProps['columns']

  @Prop({ type: Array, required: true, default: () => [] })
  public readonly items!: OptionsListProps['items']

  @Prop({ type: Number, required: false, default: 0 })
  public readonly offset!: OptionsListProps['offset']

  public get columnsCapacity (): number {
    return this.columns.responsive[this.isMobile ? 'mobile' : 'desktop'].maxColumnCapacity
  }

  public get columnsToRender (): Record<string, Record<string, Array<OptionsListItem>>> {
    let output: Record<string, Record<string, Array<OptionsListItem>>> = {}
    let counter: number = 1
    let column: number = 1

    for (let i = 0; i < this.items.length; i++) {
      output = {
        ...output,
        [column]: {
          items: [
            ...(output[column] ? output[column].items : []),
            this.items[i]
          ]
        }
      }

      // Render new column
      if (counter > this.columnsCapacity - 1) {
        column++
        counter = 1
      }

      counter++
    }

    return output
  }

  public get shouldRender (): boolean {
    return Array.isArray(this.items) && this.items.length > 0
  }

  public hasChildren (item: OptionsListItem): boolean {
    return !!item.children && item.children.length > 0
  }

  public isDisabled (item: OptionsListItem): boolean {
    return item.label.includes('(0)')
  }

  public isSelected (item: OptionsListItem): boolean {
    return !!this.selected.find((selectedItem) => selectedItem.value === item.value)
  }

  public onModelUpdate (item: OptionsListItem): void {
    this.$emit('updateSelected', item)
  }

  /**
   * Returns element label without fixed `└` sign at the beginning
   */
  public rawChildrenLabel (item: OptionsListItem): string {
    const elements = item.label.split(' ')
    return elements[0] === '└' ? elements.slice(1, elements.length).join(' ') : item.label
  }
}
