// Copyright © 2021 Move Closer

import { Injectable } from '@movecloser/front-core'

import { AsyncRelatedService } from '@/shared/modules'

import { IRelatedService } from './related.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
@Injectable()
export class RelatedService extends AsyncRelatedService implements IRelatedService {}
