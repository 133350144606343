// Copyright © 2021 Move Closer

import { BootstrapDSLConfiguration } from '@movecloser/ui-core'
import { VueConstructor } from 'vue'

import { iconsRegistry } from '@/shared/modules'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const bootstrapDSLConfiguration: BootstrapDSLConfiguration = {
  icons: Object.entries(iconsRegistry).reduce<Record<string, VueConstructor>>(
    (acc, [iconName, iconDefinition]) => ({
      ...acc, [iconName]: iconDefinition.component
    }), {}),
  prefix: 'Ui'
}
