













import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { getMetaInfo } from '@support/meta-info'
import { Inject } from '@plugin/inversify'
import { ISiteService, SiteServiceType } from '@service/site'

import { ScrollToTop } from '@/shared/modules/src/components/molecules/ScrollToTop'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl >
 */
@Component<App>({
  name: 'App',
  components: {
    ScrollToTop,
    ErrorDetails: () => import(
      /* webpackChunkName: "components/ErrorDetails" */
      './shared/components/ErrorDetails/ErrorDetails.vue'
    )
  },
  metaInfo (): MetaInfo {
    return getMetaInfo(this)
  },
  beforeRouteUpdate (to, from, next): void {
    this.$store.dispatch('pushRouteToHistory', from)
      .then(() => { next() })
  }
})
export class App extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  /**
   * Determines whether there are any errors to display.
   */
  public get hasErrors (): boolean {
    return this.$errorHandler.error !== null
  }
}

export default App
