// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { ISite, SiteData, SitePropertyTypes } from './site.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class SiteModel extends Model<SiteData> implements ISite {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'address',
      'basePath',
      'domain',
      'favicon',
      'id',
      'name',
      'locale',
      'logo',
      'postfix',
      'properties',
      'searchLabels'
    ]
  }

  public getProperty (key: string, defaultValue: SitePropertyTypes = null): SitePropertyTypes {
    if (!this._data.properties[key]) {
      return defaultValue
    }

    return this._data.properties[key]
  }

  public isMaintenanceMode (): boolean {
    return !!this.getProperty('maintenance', false)
  }
}
