// Copyright © 2021 Move Closer

import { Injectable, mapModel } from '@movecloser/front-core'
import { MetaPropertyProperty } from 'vue-meta'

import { Identifier, log, Label } from '@/shared/modules'

import { ISite, ISiteService, SiteData, ISiteModel, SitesMap } from './site.contracts'
import { siteAdapterMap } from './site.adapter'
import { SiteModel } from './site.model'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class SiteService implements ISiteService {
  protected activeSiteId: Identifier = 1
  protected activeSite: ISiteModel | undefined = undefined

  protected sites: ISiteModel[] = []

  public getActiveSite (): ISiteModel {
    if (!this.activeSite) {
      throw new Error('[SiteService]: Missing site.')
    }

    return this.activeSite
  }

  public getActiveSiteAddress (): string {
    return this.getActiveSite().address
  }

  public getActiveSiteBasePath (): string {
    const site = this.getActiveSite()
    return process.env.BASE_URL + site.postfix
  }

  public getActiveSiteLocale (): string {
    const site = this.getActiveSite()

    return site.locale.toLowerCase()
  }

  public getActiveSiteMeta (): MetaPropertyProperty[] {
    const activeSite: ISiteModel = this.getActiveSite()
    const meta: MetaPropertyProperty[] = []

    if (activeSite.properties.twitterSite && typeof activeSite.properties.twitterSite === 'string') {
      meta.push({ property: 'twitter:site', content: activeSite.properties.twitterSite })
    }

    if (activeSite.properties.siteName && typeof activeSite.properties.siteName === 'string') {
      meta.push({ property: 'og:site_name', content: activeSite.properties.siteName })
    }

    return meta
  }

  /**
   * @inheritDoc
   */
  public getSites (): ISiteModel[] {
    return this.sites
  }

  public getSiteById (id: Identifier): ISiteModel | undefined {
    return this.sites.find(s => s.id === id)
  }

  public setActive (site: ISiteModel, searchLabels: Label[]): void {
    log('🏷 SiteService.setActive()')

    this.activeSite = SiteModel.hydrate<SiteData, ISite>(
      mapModel({ ...site, searchLabels: searchLabels }, siteAdapterMap)
    )
    this.activeSiteId = site.id

    log(['this.activeSite:', this.activeSiteId])
    log(['this.getActiveSite():', JSON.stringify(this.activeSite)])
  }

  public setSites (sites: SitesMap, searchLabels: Label[]): void {
    for (const payload of Object.values(sites)) {
      this.sites.push(
        SiteModel.hydrate<SiteData, ISite>(
          mapModel({ ...payload, searchLabels: searchLabels }, siteAdapterMap)
        )
      )
    }
  }
}
