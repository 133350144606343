// Copyright © 2021 Move Closer

import { Locale } from 'vue-i18n'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ISetupService {
  /**
   * Locale of the currently-active site.
   */
  readonly currentLocale: Locale

  /**
   * Determines whether the app should be put in the "maintenance" mode.
   */
  readonly isMaintenanceMode: boolean

  /**
   * Sets-up the application using the data from the API's response.
   *
   * @param entryUrl - URL address entered by the User.
   * @param isClient - Determines whether the app is running on the client side.
   */
  initApp (entryUrl: string, isClient: boolean): Promise<void>
}

export const SetupServiceType = Symbol.for('ISetupService')
