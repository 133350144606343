// Copyright © 2021 Move Closer

import { AppModule, Interfaces, Module } from '@movecloser/front-core'

import {
  GlobalSearchRepository,
  GlobalSearchRepositoryType,
  IGlobalSearchRepository,
  IJobOffersRepository,
  JobOffersRepository,
  JobOffersRepositoryType
} from '@/shared/modules'

import { ContentRepository, ContentRepositoryType, IContentRepository } from './repositories'
import { routesFactory as routes } from './routes'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@AppModule({
  name: 'content',
  routes,

  providers: () => {
    return (bind: Interfaces.Bind) => {
      bind<IContentRepository>(ContentRepositoryType).to(ContentRepository)
      bind<IJobOffersRepository>(JobOffersRepositoryType).to(JobOffersRepository)
      bind<IGlobalSearchRepository>(GlobalSearchRepositoryType).to(GlobalSearchRepository)
    }
  }
})
export class ContentModule extends Module {}
