// Copyright © 2021 Move Closer

import { ConnectorMiddleware, FoundResource, Headers, Injectable, IResponse, Payload } from '@movecloser/front-core'

import { log, RelatedRecord } from '@/shared/modules'

import { IRelatedService } from './related.contracts'

/**
 * Middleware that intercepts API's responses,
 * extracts the `meta` field and passes it to the `RelatedService`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Injectable()
export class RelatedMiddleware implements ConnectorMiddleware {
  private readonly relatedService: IRelatedService

  constructor (relatedService: IRelatedService) {
    this.relatedService = relatedService
  }

  /**
   * @inheritDoc
   */
  public afterCall (response: IResponse): void {
    log('🏷 RelatedMiddleware.afterCall()')

    const isRegistry: boolean = response?.data?.data?.type === 'page'
    log(['isRegistry:', isRegistry])

    const record: RelatedRecord = response?.data?.meta?.dicts
    const hasRecord: boolean = typeof record === 'object' && record !== null

    if (!isRegistry || !hasRecord) {
      log('RelatedRecord will NOT be updated')
      return
    }

    log('RelatedRecord WILL be updated')
    log(['record:', Object.entries(record)
      .reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: typeof value === 'object'
            ? Object.keys(value).length
            : value
        }
      }, {})
    ])

    this.relatedService.storeRelated(record)
  }

  /**
   * @inheritDoc
   */
  public beforeCall (resource: FoundResource, headers: Headers, body: Payload): { body: Payload; headers: Headers } {
    log('🏷 RelatedMiddleware.beforeCall()')
    log(['resource.url:', resource.url])

    return { body, headers }
  }
}

export const RelatedMiddlewareType = Symbol.for('RelatedMiddleware')
