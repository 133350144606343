"use strict";
// Copyright © 2021 Move Closer
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventListener = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const vue_property_decorator_1 = require("vue-property-decorator");
const support_1 = require("../../support");
/**
 * Extendable component that's capable of registering the event listeners
 * and unregistering them before the component gets destroyed.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let EventListener = class EventListener extends vue_property_decorator_1.Vue {
    constructor() {
        super(...arguments);
        /**
         * Registry that binds the event type with the array of listeners
         * (functions/handlers) registered under this type.
         */
        this.eventsRegistry = {};
    }
    /**
     * Registers the specified event type/listener combination.
     *
     * @see Window.addEventListener
     */
    addEventListener(type, listener, options) {
        if (typeof window === 'undefined') {
            support_1.log('EventListener.addEventListener(): [window] is [undefined]! Aborting!');
            return;
        }
        try {
            window.addEventListener(type, listener, options);
            if (typeof this.eventsRegistry[type] === 'undefined') {
                // @ts-expect-error - These are some complicated type annotations and probably there's a proper way
                // to write these, but at this moment I really don't have idea on HOW to do this,
                // so I'm falling back to this suppression.
                this.eventsRegistry[type] = [{ listener, options }];
            }
            else {
                // @ts-expect-error - The "Symbol.iterator()" error would happen if we would attempt to spread the value
                // that's undefined, but since we made the necessary check above, we probably can suppress this warning.
                this.eventsRegistry[type] = [...this.eventsRegistry[type], { listener, options }];
            }
        }
        catch (error) {
            const message = 'EventListener.addEventListener(): Failed to add the event listener!';
            support_1.log([message, error], 'error');
        }
    }
    /**
     * Removes (unregisters) all registered event listeners.
     */
    removeEventListeners() {
        if (typeof window === 'undefined') {
            support_1.log('EventListener.removeEventListener(): [window] is [undefined]! Aborting!');
            return;
        }
        Object.entries(this.eventsRegistry).forEach(([type, events]) => {
            if (typeof events === 'undefined') {
                support_1.log(`EventListener.removeEventListener(): There are no events registered for the [${type}] type!`, 'warn');
                return;
            }
            events.forEach((config) => {
                const { listener, options } = config;
                try {
                    window.removeEventListener(type, listener, options);
                }
                catch (error) {
                    const message = `EventListener.removeEventListener(): Failed to remove the event listener for the [${type}] type!`;
                    support_1.log([message, error, listener, options], 'error');
                }
            });
        });
    }
};
EventListener = __decorate([
    vue_property_decorator_1.Component({
        name: 'EventListener',
        beforeDestroy() {
            this.removeEventListeners();
        }
    })
], EventListener);
exports.EventListener = EventListener;
