// Copyright © 2021 Move Closer

import { RoutesFactory } from '@movecloser/front-core'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const routesFactory: RoutesFactory = () => {
  return [
    {
      path: '/error',
      name: 'error',
      component: () => import(
        /* webpackChunkName: "views/ErrorView" */
        './views/ErrorView/ErrorView.vue'
      )
    }
  ]
}
